<template>
  <div>
    <b-card>
      <filter-swapper
        v-if="!loading.first"
        :trigger="selectedRows.length === 0"
        :buttons="buttons"
        :controlHeightButtons="controlHeight"
      >
        <template #slot1>
          <form-render
            :form.sync="formFilter"
            :key="keyFormRender"
            :fields="fields"
            @send="filterList"
            :buttonSend="buttonSend"
            ref="formFilter"
            containerButtonsClass="col-sm-12 col-md-12 col-lg-5 col-xl-4 container-button mt-2"
          >
            <template #buttons>
              <b-button
                variant="outline-light"
                v-b-tooltip.hover
                title="Limpiar filtros"
                :disabled="loading.controlBilling"
                class="ml-1"
                @click="cleanFilter"
              >
                <feather-icon icon="RefreshCwIcon" />
              </b-button>
              <b-button
                variant="link"
                v-b-tooltip.hover
                title="Búsqueda avanzada con filtros extras"
                class="ml-1 lnk lnk-primary search-advanced"
                :disabled="loading.controlBilling"
                @click="openModalAdvanceSearch"
              >
                Busqueda avanzada
              </b-button>
            </template>
            <template #extra>
              <div class="col-sm-12 col-md-12 mb-2">
                <div class="d-flex justify-content-end">
                  <b-button @click="downloadExcel" variant="outline-success">
                    <feather-icon v-if="!loading.excel" icon="FileIcon"/>
                    <i v-else class="fa fa-spinner fa-spin"></i>
                    {{$t('Exportar a excel')}}
                  </b-button>
                </div>
              </div>
            </template>
          </form-render>
        </template>
      </filter-swapper>


      <table-render
        :key="keyTableRender"
        v-if="!loading.first"
        id="table_folders"
        :rows="rows"
        :schema="schema"
        :actions="actions"
        :columnFilter="true"
        :defaultColumns="defaultColumns"
        :loading="loading.controlBilling"
      />

      <pagination
        v-if="!loading.first"
        :pagination="pagination"
        :noDigits="false"
        :showSize="true"
        :disabled="loading.controlBilling"
      />
      <b-skeleton
        height="40px"
        width="100%"
        class="mt-2 mb-2 spacing-label-field"
        v-show="loading.first"
      />
      <div class="table-render-skeleton" v-show="loading.first">
        <b-skeleton-table
          :rows="pagination.limit || 10"
          :columns="schema.length || 10"
          :table-props="{}"
        />
      </div>
    </b-card>
    <modal-advance-search-control  @send="filterList" :filter="[]" :countries="optionCountry" :executives="[]" :companies="optionCompanies" :formSuperior="formFilter" :couriers="optionCouriers" @country-changed="onCountryChanged" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalAdvanceSearchControl from './ModalAdvanceSearch.vue'
import BillingService from '../../billing.service'
export default {
  name: 'crud-control-billing',
  components: {
    ModalAdvanceSearchControl
  },
  data() {
    return {
      selectedRows: [],
      formFilter: {},
      payloadFilter: {},
      loading: {
        first: true,
        controlBilling: true,
        excel: false
      },
      buttons: [],
      environment: null,
      rows: [],
      schema: [],
      fields: [],
      actions: [],
      pagination: { page: 1, limit: 10, total: 0, loading: false },
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      buttonSend: { icon: 'SearchIcon', color: 'warning', title: 'Filtrar', disabled: false },
      keyFormRender: 0,
      keyTableRender: 0,
      enableExport: false,
      sortProps: null,
      valueTags: [],
      statusRequest: null,
      optionCountry: [
        {value: 1, text: 'Chile', code: 'CL', id: '1', columns: ['courier', 'batchCode', 'ot', 'service',  'freightCost', 'freightPrice', 'user', 'billedWeight', 'totalCost', 'totalPrice'] },
        {value: 2, text: 'México', code: 'MX', id: '2', columns: ['courier', 'batchCode', 'ot', 'service',  'freightCost', 'freightPrice', 'user', 'billedWeight', 'totalCost', 'totalPrice'] },
        {value: 3, text: 'Colombia', code: 'CO', id: '3', columns: ['courier', 'batchCode', 'ot', 'service',  'freightCost', 'freightPrice', 'user', 'billedWeight', 'totalCost', 'totalPrice'] },
        {value: 4, text: 'Perú', code: 'PE', id: '4', columns:  ['courier', 'batchCode', 'ot', 'service',  'freightCost', 'freightPrice', 'user', 'billedWeight', 'totalCost', 'totalPrice'] }
      ],
      optionCompanies: [],
      optionCouriers: [],
      baseService: new BillingService(this),
      defaultColumns: ['courier', 'batchCode', 'ot', 'service', 'freightCost', 'freightPrice', 'user', 'billedWeight', 'totalCost', 'totalPrice'],
      allColumnsSchema: [
        { label: 'ID-Cliente', key: 'client_id', countrys: ['CL', 'MX', 'CO', 'PE'] },
        { label: 'OT', key: 'ot', countrys: ['CL', 'MX', 'CO', 'PE'] },
        { label: 'Código de carga', key: 'batchCode', countrys: ['CL', 'MX', 'CO', 'PE'] },
        { label: 'Usuario de carga', key: 'user', countrys: ['CL', 'MX', 'CO', 'PE'] },
        { label: 'Courier', key: 'courier', countrys: ['CL', 'MX', 'CO', 'PE'] },
        { label: 'Servicio', key: 'service', countrys: ['CL', 'MX', 'CO', 'PE'] },
        { label: 'Origen', key: 'origin', countrys: ['CL', 'MX', 'CO', 'PE'] },
        { label: 'Destino', key: 'destiny', countrys: ['CL', 'MX', 'CO', 'PE'] },
        { label: 'Peso facturado', key: 'billedWeight', countrys: ['CL', 'MX', 'CO', 'PE'] },
        { label: 'Costo Flete', key: 'freightCost', countrys: ['CL', 'MX', 'CO', 'PE'] },
        { label: 'Precio Flete', key: 'freightPrice', countrys: ['CL', 'MX', 'CO', 'PE'] },
        { label: 'Seguro', key: 'insurance', countrys: ['CL', 'PE'] },
        { label: 'Valor declarado', key: 'declaredValue', countrys: ['CO'] },
        { label: 'Sobreflete informado', key: 'freightHandlingInput', countrys: ['CO'] },
        { label: 'Sobreflete calculado', key: 'freightHandlingCalculated', countrys: ['CO']  },
        { label: 'Costo cargo combustible', key: 'fuelChargeCost', countrys: ['MX'] },
        { label: 'Precio cargo combustible', key: 'fuelChargePrice', countrys: ['MX'] },
        { label: 'Costo cargos adicionales', key: 'additionalChargesCost', countrys: ['MX'] },
        { label: 'Precio cargos adicionales', key: 'additionalChargesPrice', countrys: ['MX'] },
        { label: 'Costo total', key: 'totalCost', countrys: ['CL', 'PE', 'MX', 'CO'] },
        { label: 'Precio total', key: 'totalPrice', countrys: ['CL', 'PE', 'MX', 'CO'] }
      ]
    }
  },
  computed: {
    ...mapGetters({
      manageData: 'getManageData',
      billableCompanies: 'getBillableCompanies',
      controlData: 'getControlBilling',
      couriersControl: 'getCouriersControl'
    })
  },

  watch: {
    controlData() {
      this.rows = this.controlData.rows.map(controlData => {
        return {
          ...controlData,
          // Algunas columnas modificadas por Mauricio; los comentarios contienen el valor anterior.
          id: controlData?.id, // ¿Qué ID es?
          batchCode: controlData?.batchCode, //
          courier: controlData?.carrierService?.carrier?.name, // courier: controlData?.carrier
          service: controlData?.carrierService?.name, // service: controlData?.service
          origin: controlData?.placeFrom?.name, // controlData?.placeFrom
          destiny: controlData?.placeTo?.name, // controlData?.placeTo
          client_id: `${controlData?.delivery?.company?.id}-${controlData?.delivery?.company?.name}`, // `${controlData?.company?.id}-${controlData?.company?.name}`
          ot: controlData?.delivery?.trackingNr // controlData?.trackingNr
        }
      })
      this.loading.first = false
      this.loading.controlBilling = false
      this.buttonSend.disabled = false
      this.selectedRows = []
      this.pagination.page = this.controlData.pagination.current_page
      this.pagination.total = this.controlData.pagination.total_items
      this['pagination.limit'] = this.controlData.pagination.total_pages
    },
    billableCompanies() {
      this.optionCompanies = this.billableCompanies.map(company => {
        return {
          value: company.id,
          text: `${company.id} - ${company.name}`,
          id: company.id,
          name: company.name
        }
      })
    },
    couriersControl() {
      this.optionCouriers = this.couriersControl.map(courier => {
        return {
          value: courier.id,
          text: `${courier.id} - ${courier.name}`,
          id: courier.id,
          name: courier.name,
          services: courier.services.map(service => {
            return {
              value: service.id,
              text: `${service.id} - ${service.name}`,
              id: service.id,
              name: service.name
            }
          })
        }
      })
    },
    'pagination.page'() {
      this.getManageData()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getManageData()
    }
  },

  mounted() {
    this.setInitialData()
    this.getAllInitialData()
  },

  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldSelect', name: 'country', label: 'País', containerClass: 'col-md-2 container-info', addFlags: true, validation: 'required', options: this.optionCountry, change: this.onCountryChanged, clearable: false},
        { fieldType: 'FieldDatepicker', label: this.$t('Fecha'), name: 'date', placeholder: 'Seleccione fecha', clearable: true, containerClass: 'container-info col-md-2', range: true },
        { fieldType: 'FieldInput', name: 'ot_string', label: 'OT/Número de envío', containerClass: 'col-md-3 container-info' }
      ]
      this.schema = this.allColumnsSchema.filter(column => column.countrys.includes('CL'))
      this.formFilter.country = this.optionCountry[0]
      this.onCountryChanged(this.formFilter.country)
    },
    generateFilters(form) {
      const filters = {}
      if (form.date) {
        filters['filter[dateSince]'] = this.$options.filters.moment(form.date.start, 'YYYY-MM-DD') || ''
        filters['filter[dateUntil]'] = this.$options.filters.moment(form.date.end, 'YYYY-MM-DD') || ''
      }
      if (form.ot_string) filters['filter[otShippingNum]'] = form.ot_string
      if (form.company) filters['filter[companyId]'] = form.company.id
      if (form.courier) filters['filter[carrierId]'] = form.courier.id
      if (form.service) filters['filter[serviceId]'] = form.service.id
      if (form.batchCode) filters['filter[batchCode]'] = form.batchCode
      return filters
    },
    filterList(form) {
      this.buttonSend.disabled = true
      this.payloadFilter = {
        ...this.generateFilters(form)
      }
      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.getManageData()
    },
    cleanFilter() {
      this.formFilter = {
        country: this.optionCountry[0]
      }
      this.keyFormRender++
      this.defaultColumns = this.formFilter.country.columns
      this.schema = this.allColumnsSchema.filter(column => column.countrys.includes(this.formFilter.country.code))
      this.keyTableRender++
      this.filterList(this.formFilter)
      this.onCountryChanged(this.formFilter.country)
    },
    openModalAdvanceSearch() {
      this.$bvModal.show('ModalAdvanceSearchControl')
    },
    getAllInitialData() {
      const params = {
        country_id: this.formFilter.country.value || 1
      }
      this.getManageData()
    },
    getManageData() {
      this.loading.controlBilling = true
      const queryParams = {
        'page': this.pagination.page,
        'size': this.pagination.limit,
        ...this.payloadFilter
      }
      const params = {
        country_id: this.formFilter.country.value || 1
      }
      this.$store.dispatch('fetchServiceStatusOnError', { name: 'getControlBilling', params, queryParams, onError: this.onError })
      this.$store.dispatch('fetchService', { name: 'getCouriersControl', params })

      this.baseService.callService('getBillableCompanies', null, params).then(response => {
        this.optionCompanies = response.data.map(company => {
          return {
            value: company.id,
            text: `${company.id} - ${company.name}`,
            id: company.id,
            name: company.name
          }
        })
      }).catch(err => {
        console.error('err', err)
      })
    },
    onError(err) {
      this.loading.controlBilling = false
      this.buttonSend.disabled = false
      this.loading.first = false
      const title = err?.errors ? err.errors[0].title : 'Ocurrió un problema al cargar los datos'
      const detail = err?.errors ? err.errors[0].detail : 'Consulte con soporte por favor.'
      this.$alert(this.$t('msg-problema-personalizado', {titleErr: title, detailErr: detail}))
    },
    getBillableCompanies() {
      //this.$store.dispatch('fetchService', { name: 'getBillableCompanies', params })
      //Local request and set data in this.optionCompanies
    },
    onCountryChanged(country, value) {
      //this.formFilter.country = value
      this.schema = this.allColumnsSchema.filter(column => column.countrys.includes(this.formFilter.country.code))
      this.defaultColumns = this.formFilter.country.columns
      this.formFilter.courier = null
      this.formFilter.company = null
      this.keyTableRender++
      this.keyFormRender++
      this.getManageData()
      //this.getBillableCompanies()
    },
    downloadExcel() {
      this.loading.excel = true
      const queryParams = {
        ...this.payloadFilter
      }
      const params = {
        country_id: this.formFilter.country.value || 1
      }
      this.$store.dispatch('generateExcelBillingControl', { onSuccess: this.onLoadedExcel, queryParams, params })
      this.loading.excel = false
    },
    onLoadedExcel () {
      this.loading.excel = false
    }
  }
}
</script>

<style lang="scss">
.manage-button {
  top: -3.5rem !important;
  right: 0rem !important;
  position: absolute !important;
  z-index: 9;
}
.btn-uploadFiles-modal{
  position: fixed;
  right: 4rem;
  bottom: 2rem;
  button {
    border-radius: 50% !important;
    width: 50px;
    height: 50px;
    padding: 0;
  }
}
.btn-uploadFiles-list{
  top: 2rem !important;
  left: -9.5rem !important;
  border: 1.5px solid #1B4284 !important;
  li > {
    a{
      font-weight: bold !important;
      color: #1B4284 !important;
    }
    a:active {
      color: white !important;
    }
  }
}
[dir=ltr] .btn-group [class*=btn-]:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]).btn-uploadFiles-list{
  border-left-color: #1B4284 !important;
  border-right-color: #1B4284 !important;
}
.select-optgroup-title{
  opacity: 1 !important;
  font-weight: bold !important;
  color: blue;
}
.badge-cnt-filter{
  margin: 5px 5px 0 5px;
}
.blocked-element{
  opacity: 0.5;
}
.blocked-element, .blocked-element p.font-weight-bold a {
  cursor: not-allowed !important;
}
</style>
