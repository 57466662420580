<template>
    <b-modal
      id="ModalAdvanceSearchControl"
      :title="$t('Búsqueda Avanzada')"
      modal-class="custom-dialog dialog-600"
      ok-only
      :ok-title="$t('Buscar')"
      ok-variant="warning"
      centered @ok="ok">
      <div class="mt-0">
        <form-render :form.sync="formSuperior" :fields="fields" @send="formRenderFilterSend"
          ref="formRenderFilter" :key="keyFormRender"
          containerButtonsClass="col-sm-12 col-lg-4 container-button">
        </form-render>
      </div>
    </b-modal>
</template>
<script>
export default {
  name:'modalAdvanceSearchControl',
  props: ['filter', 'countries', 'companies', 'formSuperior', 'couriers'],
  data () {
    return {
      fields: [],
      keyFormRender: 0
    }
  },
  watch: {
    companies() {
      this.fields[5].options = this.companies
      this.fields[5].disabled = false
      this.formSuperior.company = null
      this.keyFormRender++
    },
    couriers() {
      this.fields[3].options = this.couriers
      this.fields[3].disabled = false
      this.formSuperior.service = null
      this.keyFormRender++
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    handleCountryChangeInModal(country, value) {
      this.fields[3].disabled = true
      this.fields[5].disabled = true
      this.keyFormRender++
      this.$emit('country-changed', value)
    },
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldSelect', label: 'País', name: 'country', options: this.countries, clearable: false, containerClass: 'col-md-12 container-info', addFlags: true, change: this.handleCountryChangeInModal },
        { fieldType: 'FieldDatepicker', label: this.$t('Fecha'), name: 'date', placeholder: 'Seleccione rango defecha', clearable: true, containerClass: 'container-info col-md-12', range: true },
        { fieldType: 'FieldInput', name: 'ot_string', label: 'OT/Número de envío', containerClass: 'col-md-12 container-info' },
        { fieldType: 'FieldSelect', name: 'courier', label: 'Courier', options: this.couriers, containerClass: 'col-md-12 container-info', change: this.handleCourierChangeInModal},
        { fieldType: 'FieldSelect', name: 'service', label: 'Servicio', dependency: 'courier', containerClass: 'col-md-12 container-info', options: [], disabled: true },
        {fieldType: 'FieldSelect', name: 'company', label: 'ecommerce/marketplace', containerClass: 'col-md-12 container-info', options: this.companies, placeholder: 'Ingrese el nombre o el id de ecommerce/marketplace', disabled: false },
        {fieldType: 'FieldInput', label: 'Código de carga', name: 'batchCode', clearable: true, containerClass: 'col-md-12 container-info' }
      ]
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderFilter.checkForm()
    },
    handleCourierChangeInModal(courier, value) {
      this.formSuperior.service = null
      this.fields[4].disabled = false
      if (value) {
        this.fields[4].options = value.services
      }
      this.keyFormRender++
    },
    formRenderFilterSend() {
      this.$emit('send', this.formSuperior)
      this.$bvModal.hide('ModalAdvanceSearchControl')
    }
  }

}
</script>

<style>
h5 {
  font-size: 1.5rem;
  font-weight: 600;
}
</style>
